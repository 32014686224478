/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:90973be5-07c9-4eca-8252-cbc4c7d97d4c",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_4NrLOB53S",
    "aws_user_pools_web_client_id": "66f7alakeisq6iqqmmm9m1393c",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://etgsoctqcfbu5d5wxbca6yldcm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
